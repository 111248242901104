@use 'styles/config' as *;

.header {
  position: absolute 0;
  bottom: auto;
  z-index: 1001;

  padding: 1.5rem 0 0;

  pointer-events: none;

  &.fixed {
    @media (min-width: $min-1080) {
      position: fixed;
    }
  }

  @media (min-width: $min-720) {
    padding: 4rem 0 0;
  }

  &__container {
    @include containerNoLimit();

    width: 100%;
  }

  &__logo {
    @include reset-anchor();
    margin-left: -1rem;

    &.main {
      pointer-events: auto;
    }

    @media (min-width: $min-720) {
      margin-left: 0;
    }

    > svg {
      width: 11rem;

      @media (min-width: $min-720) {
        width: 15.6rem;

        :global(.lotus) & {
          width: 14.7rem;
        }

        // :global(.almatica) & {
        //   width: 25rem;
        // }
      }

      path {
        fill: #232323;
        transition: 200ms;
      }
    }

    &.white > svg path {
      fill: #fff;
    }

    &.yellow > svg path {
      fill: brand();
    }

    // Adalvo logo
    :global(.adalvo) & > svg {
      path {
        fill: #da3731;
      }
    }

    // Alvotech logo
    :global(.alvotech) & > svg {
      path:nth-child(1) {
        fill: #f89e1c;
      }
      path:nth-child(2) {
        fill: #3e3e3f;
      }
    }
    :global(.alvotech) &.yellow > svg {
      path:nth-child(2) {
        fill: #fff;
      }
    }
    :global(.alvotech) &.navOpen > svg {
      path:nth-child(2) {
        fill: #fff;
      }
    }

    // Almatica logo
    :global(.almatica) & > svg {
      path {
        fill: #595a5c;
      }

      path:nth-child(9),
      path:nth-child(10),
      path:nth-child(11) {
        fill: brand();
      }
    }

    :global(.almatica.dark-theme) & {
      path {
        fill: #fff;
      }
    }

    :global(.almatica) &.navOpen > svg,
    :global(.almatica) &.yellow > svg,
    :global(.almatica) &.dark > svg {
      path {
        fill: #fff;
      }

      path:nth-child(9),
      path:nth-child(10),
      path:nth-child(11) {
        fill: brand();
      }
    }

    // Almaject logo
    :global(.almaject) & > svg {
      path:first-child {
        fill: #000;
      }

      path:last-child {
        fill: brand();
      }
    }

    :global(.almaject) &.navOpen > svg,
    :global(.almaject) &.yellow > svg,
    :global(.almaject) &.dark > svg {
      path:first-child {
        fill: #fff;
      }
    }
  }

  &__skip {
    @include reset-anchor;
    @include brandColor(background-color);
    @include copy(1.4rem, 1.4rem);
    pointer-events: auto;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: 1005;

    margin: auto;
    padding: 0.5rem 1rem;

    width: max-content;
    opacity: 0;
    transform: translateY(-100%);

    color: $color-font;

    &:focus {
      opacity: 1;
      color: $color-font;
      transform: translateY(0);
    }
  }
}
