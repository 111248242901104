@use 'styles/config' as *;

.nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  color: #fff;
  // background: linear-gradient(180deg, rgba(#000, 0.4), transparent);

  @media (min-width: $min-960) {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
  }

  &__nav {
    @include containerNoLimit();
    padding-left: 4rem !important;
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-top: 2rem;
    padding-bottom: 4rem;
    color: $color-dark;
    background: transparent;

    :global(body.white) & {
      color: $color-dark;
      background: #fff;

      :global(.dark-theme) & {
        color: #fff;
        background: #1e1e1e;
      }
    }

    :global(body.image) & {
      color: $color-dark;
      background: transparent;
    }

    :global(body.brand) & {
      color: $color-dark;
      background: brand();
    }

    :global(body.dark) & {
      color: #fff;
      background: $color-dark;
    }
  }

  &__toggle {
    @include reset-button;
    color: inherit;
    transition: color 200ms;
    cursor: pointer;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 0.4rem;
    }

    &:hover {
      &:hover,
      .isActive & {
        color: brand();
      }
    }

    :global(body.dark) & {
      color: brand();

      &:hover,
      &.isActive {
        color: #fff;
      }
    }

    :global(body.brand) & {
      &:hover,
      &.isActive {
        color: #fff;
      }
    }

    .isOpen &,
    :global(body.image) & {
      color: brand();

      &:hover,
      &.isActive {
        color: #fff;
      }

      :global(.almatica) & {
        color: #fff;

        &:hover,
        &.isActive {
          color: brand();
        }
      }
    }
  }

  &__dropdown {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    height: 0;
    overflow: hidden;
    background: #111;
  }

  &__container {
    @include containerNoLimit();
    // @include container();
  }

  &__top {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    height: 3.8rem;

    font-size: 1.2rem;
    font-weight: 600;

    // pointer-events: none;
    // opacity: 0;
    z-index: 10;

    a {
      @include reset-anchor;
      display: flex;
      align-items: center;
      color: inherit;

      transition: color 200ms;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      .isOpen & {
        transition: color 200ms;
      }

      &:hover {
        &:hover,
        &.isActive {
          color: brand();
        }
      }

      :global(body.dark) & {
        color: brand();

        &:hover,
        &.isActive {
          color: #fff;
        }
      }

      :global(body.brand) & {
        &:hover,
        &.isActive {
          color: #fff;
        }
      }

      .isOpen &,
      :global(body.image) & {
        color: brand();

        &:hover,
        &.isActive {
          color: #fff;
        }

        :global(.almatica) & {
          color: #fff;

          &:hover,
          &.isActive {
            color: brand();
          }
        }
      }
    }
  }

  &__main {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;

    margin-top: responsive(0rem, 0.2rem, 96rem, 128rem);
    height: 3rem;

    font-size: responsive(1.6rem, 1.8rem, 108rem, 128rem);
    font-weight: 600;
  }
}

.navigation {
  position: relative;

  &__list {
    justify-self: flex-end;
    display: flex;
    justify-content: center;
    // gap: 2rem;

    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: $min-1080) {
      // gap: 3rem;
    }
  }

  &__item {
    position: relative;

    &:hover,
    &.isActive {
      color: brand();
    }
  }

  :global(body.image) &__item:hover &__trigger,
  :global(body.image) &__item:hover &__link {
    color: brand();
  }

  :global(body.brand) & {
    &:hover,
    &.isActive {
      color: #fff;
    }
  }

  &__item:first-child {
    margin-left: -1rem;

    @media (min-width: $min-1080) {
      margin-left: -1.5rem;
    }
  }

  &__item:last-child {
    margin-right: -1rem;

    @media (min-width: $min-1080) {
      margin-right: -1.5rem;
    }
  }

  &__trigger,
  &__link {
    outline: none;
    user-select: none;

    font-size: responsive(1.6rem, 1.8rem, 108rem, 128rem);
    font-weight: 600;

    // .isOpen & {
    //   transition: color 200ms;
    // }

    // &:hover,
    &:focus-visible,
    &.isActive {
      color: brand();
    }

    :global(body.image) & {
      color: #fff;

      // &:hover,
      &:focus-visible,
      &.isActive {
        color: brand();
      }
    }

    :global(body.brand) & {
      // &:hover,
      &:focus-visible,
      &.isActive {
        color: #fff;
      }
    }

    :global(.dark-theme) & {
      color: #fff;

      // &:hover,
      &:focus-visible,
      &.isActive {
        color: brand();
      }
    }

    // body .isOpen & {
    //   color: #fff;

    //   // &:hover,
    //   &.isActive {
    //     color: brand();
    //   }
    // }
  }

  &__trigger {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &[data-state='open'] {
      color: brand();
    }

    &::before {
      content: '';
      position: absolute;
      top: -2rem;
      left: 0;
      right: 0;
      height: 2rem;
      z-index: 1;

      // background: red;
    }
  }

  &__link {
    @include reset-anchor;
    display: block;
    text-decoration: none;
  }

  &__trigger,
  &__link {
    padding: 0 1rem;

    @media (min-width: $min-1080) {
      padding: 0 1.5rem;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;

    position: absolute;
    top: 100%;
    left: 50%;

    transform-origin: top left;
    transform: translateX(-50%);

    padding-top: 2rem;

    // transition: height 250ms ease;

    // &[data-state='open'] {
    //   animation: scaleIn 200ms ease;
    // }

    // &[data-state='closed'] {
    //   animation: scaleOut 200ms ease;
    // }
  }

  &__item:first-child &__content {
    left: -2rem;
    transform: translateX(0%);
    right: auto;

    @media (min-width: $min-1080) {
      left: -1.5rem;
    }
  }

  &__item:last-child &__content {
    left: auto;
    transform: translateX(0%);
    right: 1rem;

    @media (min-width: $min-1080) {
      right: 1.5rem;
    }
  }

  &__wrap {
    position: relative;
    top: 0;

    // [data-top-state='open'] & {
    //   height: auto;
    // }

    // [data-state='closed'] & {
    //   height: 0;
    // }
  }

  &__inner {
    overflow: hidden;
    background-color: #111;
  }

  &__subList {
    @include reset-list;
    display: grid;

    padding: 1.5rem 3rem;

    width: 30rem;

    // transform-origin: top left;

    animation-duration: 250ms;
    animation-timing-function: ease;

    [data-motion='from-start'] & {
      animation-name: enterFromLeft;
    }

    [data-motion='from-end'] & {
      animation-name: enterFromRight;
    }

    [data-motion='to-start'] & {
      animation-name: exitToLeft;
    }

    [data-motion='to-end'] & {
      animation-name: exitToRight;
    }
  }

  &__indicator {
    position: absolute;
    top: 0.2rem;
    left: 50%;
    // left: 50%;

    // display: flex;
    // align-items: flex-end;
    // justify-content: center;
    // height: 1rem;
    // overflow: hidden;
    // z-index: 1;
    // transition: left 250ms ease;

    // &[data-state='visible'] {
    //   animation: fadeIn 200ms ease;
    // }

    // &[data-state='hidden'] {
    //   animation: fadeOut 200ms ease;
    // }
  }

  &__item:first-child &__indicator {
    left: 30%;
  }

  &__item:last-child &__indicator {
    left: 70%;
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 50%;
    background-color: #111;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg) translateX(-50%) translateY(-50%);
    border-top-left-radius: 0.2rem;

    // transition: opacity 0ms 30ms ease;

    // [data-state='open'] & {
    //   display: block;
    // }

    // [data-state='closed'] & {
    //   display: none;
    // }
  }

  &__viewport {
    position: relative;
    transform-origin: top center;
    overflow: hidden;

    margin-top: 1rem;
    width: auto;
    background-color: #111;

    // box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;

    width: var(--radix-navigation-menu-viewport-width);
    height: var(--radix-navigation-menu-viewport-height);
    transition: width, height, 300ms ease;

    &[data-state='open'] {
      animation: scaleIn 200ms ease;
    }

    &[data-state='closed'] {
      animation: scaleOut 200ms ease;
    }
  }
}

.item {
  @include reset-anchor;
  display: block;
  padding: 1rem 0;

  color: #fff;

  transition: color 200ms;

  &:focus-visible,
  &:hover {
    color: brand();
  }

  &.isActive {
    color: brand();
  }
}

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(20rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-20rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(20rem);
  }
}

@keyframes exitToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-20rem);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
