@use 'styles/config' as *;

.languages {
  display: flex;
  align-items: center;
  position: relative;

  &.mobile {
    align-items: flex-start;
    flex-direction: column;
  }

  // color: #fff;

  &__button {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      display: block;
      width: 1.7rem;
      height: 1.6rem;
      margin-right: 1ch;
    }

    .mobile & {
      display: none;
    }
  }

  &__label {
    display: none;

    .mobile & {
      display: flex;
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 0.05em;
      margin-bottom: 0.75rem;

      svg {
        display: block;
        width: 1.7rem;
        height: 1.6rem;
        margin-right: 1ch;
      }
    }
  }

  &:not(.mobile) &__list {
    @include reset-list;

    display: flex;
    flex-direction: column;
    opacity: 0;

    pointer-events: none;

    position: absolute;
    top: calc(100% + 0.5rem);
    left: 50%;

    padding: 2rem 3rem;

    color: #fff;

    background: rgba(#232323, 0.9);
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);

    will-change: transform;
    transform: translateX(-50%) translateY(-1rem);

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      width: 0;
      height: 0;
      left: 50%;
      border: 1rem solid transparent;
      border-top: none;
      border-bottom: 0.8rem solid rgba(#232323, 0.9);
      transform: translateX(-50%);
    }
  }

  &:not(.mobile).open &__list {
    display: flex;
    pointer-events: auto;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    transition: opacity 300ms, transform 300ms;
    // row-gap: 0.5rem;
  }

  &.mobile &__list {
    @include reset-list;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5rem;
  }

  &__item {
    white-space: nowrap;
  }

  &__link {
    display: block;
    padding: 0.5rem 0;
    // color: $color-font;
    font-size: 1.6rem;

    .mobile & {
      line-height: 1.5;
      font-size: 1.2rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #9b9b9b;
      padding: 0.5rem 0;

      @media (min-width: $min-600) {
        color: #fff;
      }
    }
  }
}
