@use 'styles/config' as *;

.background {
  // position: relative;

  flex: 1 1 auto;
  width: 100%;
  min-height: 100%;

  transition: background-color $transition-background;

  &,
  &.white {
    background: #fff;

    :global(.dark-theme) & {
      color: #fff;
      background: #1e1e1e;
    }
  }

  &.brand {
    background: brand();
  }

  &.dark {
    background: brandDark();

    :global(.adalvo) & {
      background: brandDark(0.98);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__background {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;

    &.black {
    }
  }

  &__sticky {
    position: absolute 0;
  }

  &__video {
    width: 100%;
    height: 100%;

    object-fit: cover;
    opacity: 0.75;
  }
}
