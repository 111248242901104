@use 'styles/config' as *;
@use "sass:math";

.footer {
  @include segment-top(13rem, 7rem);
  @include nav-offset-padding();

  flex: 0 0 100%;

  font-size: 1.4rem;
  color: rgba(#fff, 0.5);
  background: $color-background-alt;

  :global(.adalvo) & {
    background-color: brandDark();
  }

  @include no-supports-grid() {
    flex: 0 1 auto;
  }

  &__container {
    @include container();
  }

  &__content {
    @include supports-grid() {
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-column-gap: $gutter;
      align-content: flex-start;
      grid-row-gap: 3rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-areas:
        'logo logo'
        'nav nav'
        'contact contact'
        'social social';

      margin-bottom: 5rem;

      @media (min-width: $min-600) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-areas:
          'logo logo logo logo logo logo logo logo logo logo logo logo'
          'nav nav nav nav nav nav nav nav contact contact contact contact'
          'nav nav nav nav nav nav nav nav social social social social';
      }

      @media (min-width: $min-720) {
        margin-bottom: 8.5rem;
      }

      @media (min-width: $min-840) {
        grid-template-areas:
          'side side side logo logo logo logo logo logo logo logo logo'
          'side side side nav nav nav nav nav nav contact contact contact'
          'side side side nav nav nav nav nav nav social social social';
      }

      @media (min-width: $min-960) {
        grid-template-areas:
          'side side side nav nav nav nav nav nav logo logo logo'
          'side side side nav nav nav nav nav nav contact contact contact'
          '. . . . . . . . . social social social';
      }
    }

    @include no-supports-grid() {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 8.5rem;
    }
  }

  &__side {
    grid-area: side;
    // @include grid-col(3);

    display: none;

    @media (min-width: $min-840) {
      display: block;
    }

    @include no-supports-grid() {
      position: absolute;
      top: 0;
      left: 0;
      width: 25%;
    }
  }

  &__navigation {
    grid-area: nav;
    // @include grid-col(6);

    @include no-supports-grid() {
      position: absolute;
      top: 0;
      left: 25%;
      width: 50%;
    }
  }

  &__contact {
    grid-area: contact;
    // @include grid-col(3);

    @include no-supports-grid() {
      flex: 0 1 auto;
      width: 25%;
      margin-bottom: 2rem;
    }
  }

  &__brand {
    grid-area: logo;

    @include no-supports-grid() {
      flex: 0 1 auto;
      width: 25%;
      margin-bottom: 2rem;
    }
  }

  &__social {
    grid-area: social;

    @include no-supports-grid() {
      flex: 0 1 auto;
      width: 25%;
    }
  }

  &__logo {
    @include reset-anchor();
    display: block;

    font-size: 1.8rem;
    font-weight: 600;
    color: #fff;

    > svg {
      display: block;
      width: 15.6rem;

      path {
        @include brandColor(fill);
      }

      @include no-supports-grid() {
        height: 5rem;
      }
    }

    // Alvotech logo
    :global(.alvotech) & > svg {
      path:nth-child(1) {
        fill: #f89e1c;
      }
      path:nth-child(2) {
        fill: #fff;
      }
    }

    // Almatica logo
    :global(.almatica) & > svg {
      path {
        fill: #fff;
      }
      path:nth-child(9),
      path:nth-child(10),
      path:nth-child(11) {
        fill: brand();
      }
    }

    // Almaject logo
    :global(.almaject) & > svg {
      path:first-child {
        fill: #fff;
      }

      path:last-child {
        fill: brand();
      }
    }
  }

  &__nav {
    &List {
      @include reset-list;
      display: flex;
      flex-flow: wrap;
      // margin-top: -0.5rem;
    }

    &Item {
      flex: 0 0 100%;
      line-height: 2rem;
    }

    &NonLink,
    &Link {
      @include reset-anchor;
      display: inline-block;
      padding: 0.5rem 0;
      transition: color $transition-enter;

      @media (max-width: $max-600) {
        padding: 1.2rem 0;
      }

      svg {
        width: 3rem;
        height: 3rem;
        opacity: 0.2;
        transition: opacity $transition-enter;

        @media (min-width: $min-600) {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }

    &Link {
      &:hover,
      &:focus {
        color: rgba(#fff, 1);

        svg {
          opacity: 1;
        }
      }
    }

    &NonLink {
    }

    &.main &List {
      display: grid;
      grid-column-gap: $gutter;
      grid-template-columns: 1fr 1fr;

      @include no-supports-grid() {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        & > * {
          flex: 0 0 50%;
        }
      }
    }

    &.social &List {
      gap: 1rem;
    }

    &.social &Item {
      flex: 0 1 auto;
    }

    &.social &Link {
      padding: 0;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        inset: -0.7rem;
      }
    }
  }

  &__link {
    @include reset-anchor;
    transition: color $transition-enter;

    &:focus,
    &:hover {
      color: rgba(#fff, 1);
    }
  }

  &__bottom,
  &__bottomText {
    @include reset-list;
    display: flex;
    flex-flow: wrap;
    column-gap: 5rem;
    row-gap: 2rem;
    justify-content: space-between;

    padding: 4rem 0 ($gutter * 2);

    border-top: 0.1rem solid rgba(#fff, 0.1);
  }

  &__legalList {
    flex: 1 1 auto;
    @include reset-list;
    display: flex;
    flex-flow: wrap;
    column-gap: responsive(2ch, 3ch);
    row-gap: 1ch;
  }

  &__policy {
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: math.div(0.67, 12) * 1em;
  }

  &__copy {
    flex: 0 1 auto;
    margin: 0;
    font-size: 1.2rem;
  }
}
